import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Playbuzz from '../components/Playbuzz'

const Cats = (props) => (
    <Layout>
        <Helmet>
            <title>Quiz: What is your cat personality?</title>
            <meta name="description" content="Discover your cat personality and lifestyle by answering these questions. Go Purr it!" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">

                <Playbuzz campaign="cats" />

                </div>
            </section>

        </div>

    </Layout>
)

export default Cats